"use strict";

window.aui = {
  env: document.currentScript ? document.currentScript.getAttribute("env") : "prod",
  version: "2.4.3",
  sdashAssetPath: document.currentScript ? document.currentScript.getAttribute("src").replace("/js/aui.min.js", "") : "https://assets.avalara.com/skylab-sdk/2.4.3",
  autocompleteSources: {},
  i18n: {
    /* an undocumented interim solution to allow EMEA CUP to
     * replace hard-coded strings with translated values
     */
    messages: {}
  }
};